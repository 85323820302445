import { track, getDefaultEventParameters } from "@telia/b2x-google-analytics";

export const trackGA4 = async (click_text: string, logged_in: boolean) => {
  const w = typeof window !== "undefined" ? window : undefined;
  const url = w?.location?.href;

  if (typeof url === "undefined") return;

  const { page_segment, business_area, page_type } = getDefaultEventParameters(new URL(url));

  track("navigation_interaction", {
    page_segment,
    business_area,
    page_type,
    logged_in,
    click_type: "footer",
    click_text,
    click_action: "click",
  });
};
